import React from 'react'
import '../home/home.scss';
import AddJob from './AddJob/AddJob';
import Footer from './Footer/Footer';
import HeroBanner from './HeroBanner/HeroBanner';
import JobMaking from './JobMaking/JobMaking';
import LifeJoke from './LifeJoke/LifeJoke';
import MoveList from './MoveList/MoveList';
import WaitingList from './WaitingList/WaitingList';
export default function Home() {
    return (
        <div>
           <>
                <HeroBanner/>
           </>
           <>
                <LifeJoke/>
           </>
           {/* <>
                <OnlinePosting/>
           </> */}
          
           <>
               <AddJob/>
           </>
           <>
            <JobMaking/>
           </>
           <>
            <WaitingList/>
           </>
           <>
            <MoveList/>
           </>
           <>
            <Footer/>
           </>
        </div>
    )
}
