import React from 'react'
import './JobMaking.scss';
import VectoFive from '../../../Assets/Images/vector5.png';
import PoasterImage from '../../../Assets/Images/poaster.png';
import FaceIcon from '../../../Assets/Images/face.png';
export default function JobMaking() {
    return (
        <div>
            <section className="job-making-section-align">
                <div className="container">
                    <div className="grid">
                        <div className="grid-items">
                            <div className="poaster-image">
                                <img src={PoasterImage} alt="PoasterImage"/>
                            </div>
                        </div>
                        <div className="grid-items">
                            <div className="vector-design-text">
                                <img src={VectoFive} alt="VectoFive"/>
                            </div>
                            <h1>What if your job was making funny jokes and posting them online? Crazy, right!?</h1>
                            <p>
                                Amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint. Velit officia consequat duis enim velit mollit. Exercitation veniam consequat sunt nostrud amet. Amet minim mollit non deserunt ullamco est sit aliqua dolor do amet 
                                sint. Velit officia consequat duis enim velit mollit. Exercitation veniam consequat sunt nostrud amet.
                            </p>
                            <div className="something-button">
                                <button>
                                    <span>Begin! Do something fun</span>
                                    <img src={FaceIcon} alt="FaceIcon"/>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    )
}
