import React from 'react'
import SoundImage from '../../../Assets/Images/sound.png';
import Icon from '../../../Assets/Images/icon.png';
import InstaIcon from '../../../Assets/Images/insta.png';
import FacebookIcon from '../../../Assets/Images/facebook.png';
import TwitterIcon from '../../../Assets/Images/twitter.png';
import LinkdinIcon from '../../../Assets/Images/linkdin.png';
import './MoveList.scss';
export default function MoveList() {
    return (
        <div>
            <section className="move-list-section-align">
                <div className="light-blur"></div>
                <div className="container">
                    <div className="text-grid">
                        <h1>Share with your friends and move up the waiting list!</h1>
                        <p>Amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint. Velit officia consequat duis enim </p>
                    </div>
                    <div className="toady-friend-box-center">
                        <div className="toady-box">
                            <div className="tell-box">
                                <div className="text-icon-alignment">
                                    <img src={SoundImage} alt="SoundImage"/>
                                    <h3>Tell your friends today!</h3>
                                </div>
                            </div>
                            <div className="social-media">
                                <div className="social-media-alignment">
                                    <img src={Icon} alt="Icon"/>
                                    <img src={InstaIcon} alt="InstaIcon"/>
                                    <img src={FacebookIcon} alt="FacebookIcon"/>
                                    <img src={TwitterIcon} alt="TwitterIcon"/>
                                    <img src={LinkdinIcon} alt="LinkdinIcon"/>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="coutner-grid">
                        <div className="coutner-grid-items">
                            <h1>5632</h1>
                            <p>total waitlist members</p>
                        </div>
                        <div className="coutner-grid-items">
                            <h1>5632</h1>
                            <p>your place on the line</p>
                        </div>
                        <div className="coutner-grid-items">
                            <h1>12  </h1>
                            <p>points earned</p>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    )
}
