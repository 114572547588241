import React from 'react'
import './HeroBanner.scss';
import BiteMap from '../../../Assets/Images/bit-map.png';
import FaceIcon from '../../../Assets/Images/face.png';
import MaskIcon from '../../../Assets/Images/mask.png';
import ManImage from '../../../Assets/Images/man.png';
import ManFirstImage from '../../../Assets/Images/new.png';
export default function HeroBanner() {
    return (
        <div>
            <section className="hero-banner">
                <div className="blur-first"></div>
                <div className="blure-sec-blue-bottom"></div>
                <div className="container">
                    <div className="round-blur"></div>
                    <div className="round-blur-first"></div>
                    <div className="smile-icon-right-align">
                    <div className="smile-icon-right"></div>
                    </div>
                    <div className="hero-grid">
                        <div className="hero-grid-items">
                            <div className="bite-map-image">
                                <img src={BiteMap} alt="BiteMap"/>
                            </div>
                            <div className="hero-text">
                                <h1>If you're reading this you're still alive. Life is short! Do something fun!</h1>
                            </div>
                            <div className="hero-button">
                                <button>
                                    <span>Begin! Do something fun</span>
                                    <img src={FaceIcon} alt="FaceIcon"/>
                                </button>
                            </div>
                            <div className="mask-image">
                                <img src={MaskIcon} alt="MaskIcon"/>
                            </div>
                            <div className="round-blur-first"></div>
                        </div>
                        <div className="hero-grid-items">
                            <div className="image-alignment">
                                <div className="first-image">
                                    <img src={ManImage} alt="ManImage"/>
                                    <div className="half-round"></div>
                                    <div className="bolt-round"></div>
                                </div>
                                <div className="sec-image">
                                    <img src={ManFirstImage} alt="ManFirstImage"/>
                                    <div className="line-image"></div>
                                </div>
                            </div>
                            <div className="extra-smaile"></div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    )
}
