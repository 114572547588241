import React from 'react'
import './WaitingList.scss';
import JoinIcon from '../../../Assets/Images/join.png';
import MailIcon from '../../../Assets/Images/mail.png';
export default function WaitingList() {
    return (
        <div>
            <section className="waiting-list-banner">
                <div className="read-blur-bottom"></div>
                <div className="container">
                    <div className="red-blure"></div>
                    <div className="grid">
                        <div className="grid-items">
                            <h1>Don’t be missing when the action starts. Join the waiting list!</h1>
                            <p>
                                Amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint. Velit officia consequat duis enim velit mollit. Exercitation veniam 
                                consequat sunt nostrud amet. Amet minim mollit non deserunt 
                            </p>
                            <div className="form-group-box">
                                <div className="form-group">
                                    <input type="text" placeholder="Name"/>
                                </div>
                                <div className="form-group">
                                    <input type="text" placeholder="Email Address"/>
                                </div>
                                <div className="join-button">
                                    <button>
                                        <span>Join the list! Standby for action</span>
                                        <img src={JoinIcon} alt="JoinIcon"/>
                                    </button>
                                </div>
                            </div>
                        </div>
                        <div className="grid-items">
                            <div className="mail-image">
                                <img src={MailIcon} alt="MailIcon"/>
                            </div>
                            <div className="ball-image-right"></div>
                            <div className="mail-last-child"></div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    )
}
