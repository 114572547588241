
import './App.css';
import Home from './components/home/Home';
import './styles/mixins/global.scss';
function App() {
  return (
    <>
      <Home/>
    </>
  );
}

export default App;
