import React from 'react'
import './Footer.scss';
import SmilImage from '../../../Assets/Images/extrra-edit.png';
import FooterBackground from '../../../Assets/Images/footer-background.png';
export default function Footer() {
    return (
        <div>
            <footer>
                <div className="container">
                    <div className="image-center-align">
                        <img src={SmilImage} alt="SmilImage"/>
                    </div>
                    <div className="footer-logo">
                        <h2>LOL</h2> 
                        <div className="footer-child-text-align">
                            <p>
                                Don’t take life too seriously; you will not 
                                make it out alive anyways!
                            </p>
                        </div>   
                    </div>
                    
                </div>
                <div className="footer-background">
                        <img src={FooterBackground} alt="FooterBackground"/>
                    </div>
            </footer>
        </div>
    )
}
