import React from 'react'
import './AddJob.scss';
import CryImage from '../../../Assets/Images/cry.png';
export default function AddJob() {
    return (
        <div>
            <section className="add-job-section-align">
                <div className="container">
                    <div className="text-grid">
                        <h1>The only job that adds years to your life</h1>
                        <div className="child-text-center-align">
                            <p>
                                Amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint. Velit officia consequat duis enim velit mollit. Exercitation veniam 
                                consequat sunt nostrud amet. Amet minim mollit non deserunt veniam consequat sunt nostrud amet.
                            </p>
                        </div>
                    </div>
                    <div className="relative-section">
                        <div className="cup-image"></div>
                        <div className="full-round"></div>
                        <div className="add-job-image-alignment">
                            <div className="add-job-img">
                                <img src={CryImage} alt="CryImage"/>
                                <div className="ball-image"></div>
                                <div className="dish-icon"></div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    )
}
