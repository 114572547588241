import React from 'react'
import './LifeJoke.scss';
import JoyIcon from '../../../Assets/Images/joy.png';
import VectorIcon from '../../../Assets/Images/vector.png';
import FaceIcon from '../../../Assets/Images/face.png';
import FaceFirstIcon from '../../../Assets/Images/face1.png';
export default function LifeJoke() {
    return (
        <div>
            <section className="life-is-joke-section-align">
                <div className="container">
                    <div className="light-blur-right-align">
                        <div className="light-blur"></div>
                    </div>
                    <div className="grid">
                        <div className="grid-items">
                            <div className="joy-icon">
                                <img src={JoyIcon} alt="JoyIcon"/>
                            </div>
                            <h1>Life is a joke, and so is this job, lol!</h1>
                            <p>
                                Amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint. Velit officia consequat duis enim velit mollit. Exercitation veniam consequat sunt nostrud amet. Amet minim mollit non deserunt ullamco est sit aliqua dolor 
                                do amet sint. Velit officia consequat duis enim velit mollit. Exercitation veniam consequat sunt nostrud amet.
                            </p>
                            <div className="vector-line">
                                <img src={VectorIcon} alt="VectorIcon"/>
                            </div>
                            <div className="do-something">
                                <button>
                                    <span>Begin! Do something fun</span>
                                    <img src={FaceIcon} alt="FaceIcon"/>
                                </button>
                            </div>
                        </div>
                        <div className="grid-items">
                            <div className="first-image-style"></div>
                            <div className="vector-sec"></div>
                            <div className="vector-three"></div>
                            <div className="vector-four"></div>
                            <div className="face-icon-center-align">
                                <img src={FaceFirstIcon} alt="FaceFirstIcon"/>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    )
}
